export enum CountrySelect {
    GERMANY = 'Deutschland'
}

interface CountrySelectInterface {
    value: string,
    label: string
}

/**
 * check if the prefix will be displayed different later
 */
export const CountrySelectItems: CountrySelectInterface[] = [
    {value: CountrySelect.GERMANY, label: `${CountrySelect.GERMANY}`}
];