
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from 'vue-class-component';
import ErrorMessageHandlerMixin from '@/misc/ErrorMessageHandler.mixins';
import {validationMixin} from 'vuelidate';
import {alpha, integer, minLength, required} from 'vuelidate/lib/validators';
import {AUTH_STORE_NAME} from "@/store/auth.store";
import {namespace} from "vuex-class";
import {APPLICATION_STORE_NAME, ApplicationStoreMutations} from "@/store/application.store";
import Company from "@/models/Company";
import {maxLength, phone} from "@/misc/CustomValidators";
import {MaxLengthValidation} from "@/enum/MaxLengthValidation.enum";
import CompanyRepository from "@/api/repositories/CompanyRepository";
import Address from "@/models/Address";
import {LocationStatus} from "@/enum/LocationStatus.enum";
import {PhonePrefix, PhonePrefixes} from "@/enum/PhonePrefix.enum";
import {CountrySelectItems} from "@/enum/CountrySelect.enum";

const AuthStore = namespace(AUTH_STORE_NAME);
const ApplicationStore = namespace(APPLICATION_STORE_NAME);

@Component({
  components: {},
  mixins: [validationMixin],
  validations: {
    updatingCompany: {
      address: {
        zip: {required, minLength: minLength(5), maxLength: maxLength(MaxLengthValidation.FIXED_ZIP), integer},
        street: {required, maxLength: maxLength(MaxLengthValidation.STREET)},
        city: {required, maxLength: maxLength(MaxLengthValidation.CITY), alpha},
        country: {required, maxLength: maxLength(MaxLengthValidation.COUNTRY), alpha},
      },
      deliveryAddress: {
        zip: {minLength: minLength(5), maxLength: maxLength(MaxLengthValidation.FIXED_ZIP), integer},
        street: {maxLength: maxLength(MaxLengthValidation.STREET)},
        city: {maxLength: maxLength(MaxLengthValidation.CITY), alpha},
        country: {maxLength: maxLength(MaxLengthValidation.COUNTRY), alpha},
      },
      contact: {
        phone: {required, maxLength: maxLength(MaxLengthValidation.PHONE), phone}
      },
    }
  }
})
export default class EditCompanyAddressComponent extends mixins(ErrorMessageHandlerMixin) {
  @ApplicationStore.Mutation(ApplicationStoreMutations.SET_COMPANY)
  private setCompany!: (company: Company) => void;

  @Prop({required: true})
  private company!: Company;

  private updatingCompany: Company = new Company();

  private enableDeliveryAddress: boolean = false;

  private addressVisible: boolean = false;

  /**
   * all available values that can be selected inside country selector
   * @private
   */
  private countrySelectItems = CountrySelectItems;

  private phonePrefix = '';
  private prefixItems = PhonePrefixes;

  created() {
    // copies the company to be able to make changes without modifying the old one
    this.updatingCompany = Company.parseFromObject({
      ...this.company,
      address: Address.parseFromObject({...this.company.address, country: 'Deutschland'}),
      deliveryAddress: Address.parseFromObject({...this.company.deliveryAddress, country: 'Deutschland'})
    });

    // checks which prefix is used from the list of available prefixes
    const findPrefix = this.prefixItems.find((i) => this.updatingCompany.contact.phone?.includes(i.value));

    // if prefix was found, updates the prefix select value and cuts it off from the phone number
    if(findPrefix) {
      this.phonePrefix = findPrefix.value;
      this.updatingCompany.contact.phone = this.updatingCompany.contact.phone.replace(findPrefix.value, '');
    }

    // sets address visible flag
    this.addressVisible = this.company.address.visible;

    // checks if the address is complete and sets the isDeliveryAddressEnabled flag
    if(Address.isAddressComplete(this.company.deliveryAddress!)) this.enableDeliveryAddress = true;
  }

  /**
   * checks if the address location status is pending
   * @private
   */
  private get isPendingLocation() {
    return this.company.address.locationStatus === LocationStatus.PENDING;
  }

  /**
   * checks if the delivery address location status is pending
   * @private
   */
  private get isPendingDeliveryLocation() {
    return this.company.deliveryAddress?.locationStatus === LocationStatus.PENDING && this.updatingCompany.deliveryAddress?.street;
  }

  /**
   * checks if the address location status is error
   * @private
   */
  private get isErrorLocation() {
    return this.company.address.locationStatus === LocationStatus.ERROR;
  }

  /**
   * checks if the delivery address location status is error
   * @private
   */
  private get isErrorDeliveryLocation() {
    return this.company.deliveryAddress?.locationStatus === LocationStatus.ERROR && this.updatingCompany.deliveryAddress?.street;
  }

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  /**
   * is called when the user clicks on the save button, validates the inputs and tries to change the password of the
   * user
   * @private
   */
  private async saveData() {
    // validates the form and checks if every input was made correctly
    this.$v.$touch();
    if (this.$v.$invalid) {
      return;
    }

    try {
      // tries to change the password in the api
      this.isLoading = true;

      // copies the updatingCompany to payload (in order to modify it)
      let payload: Company = Company.parseFromObject({
        ...this.updatingCompany,
        contact: {...this.updatingCompany.contact, phone: this.phonePrefix + this.updatingCompany.contact.phone},
        address: {...this.updatingCompany.address, visible: this.addressVisible},
        deliveryAddress: {...this.updatingCompany.deliveryAddress, visible: this.addressVisible}
      });

      // checks if the delivery address was disabled, sets every entry of the delivery address to empty string
      if(!this.enableDeliveryAddress) {
        payload.deliveryAddress = Address.parseFromObject({
          city: '',
          country: '',
          street: '',
          zip: '',
          visible: payload.deliveryAddress?.visible,
        });
      }

      // updates the company, gets the result and updates the company inside the store
      const response = await CompanyRepository.updateCompany(this.updatingCompany.id, payload);
      const updatedCompany = Company.parseFromObject({...response.data});
      this.setCompany(updatedCompany);

      // Show Success Message
      this.$notifySuccessSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_COMPANY_ADDRESS_SUCCESS');
      this.dismiss(true);
    } catch (_) {
      this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.EDIT_PROFILE_UPDATE_COMPANY_ADDRESS_ERROR');
    } finally {
      this.isLoading = false;
    }
  }

  /**
   * is called when the dialog should be dismissed, calls close dialog action
   * @param reload
   * @private
   */
  private dismiss(reload: boolean = false) {
    this.$v.$reset();
    this.$emit('closeDialog', reload);
  }
}
