export enum PhonePrefix {
    GERMANY = '+49'
}

interface PhonePrefixInterface {
    value: string,
    label: string
}

/**
 * check if the prefix will be displayed different later
 */
export const PhonePrefixes: PhonePrefixInterface[] = [
    {value: PhonePrefix.GERMANY, label: `${PhonePrefix.GERMANY}`}
];